import "./home/home.scss";
import check_icon from "../assets/icons/1_check2.png";
import sponsor_puntochg from "../assets/sponsors/3_PuntoCHG.png";
import sponsor_caliente from "../assets/sponsors/4_CalienteMX.png";
import sponsor_value from "../assets/sponsors/5_Value.png";
import caja_popular from "../assets/sponsors/caja_todos.png";
import voit from "../assets/sponsors/voit.png";

function createSectionTitle(
  title_text,
  container,
  more_link = false,
  more_link_url = ""
) {
  let title_container = document.createElement("div");
  title_container.classList.add("title_container");
  let title_check = document.createElement("img");
  title_check.src = check_icon;
  title_check.classList.add("title_icon");

  let title = document.createElement("span");
  title.innerHTML = title_text;

  title_container.appendChild(title_check);
  title_container.appendChild(title);

  container.appendChild(title_container);

  if (more_link) {
    let more_link_anchor = document.createElement("a");
    more_link_anchor.href = more_link_url;
    more_link_anchor.classList.add("more_link_anchor");

    let more_link_text = document.createElement("span");
    more_link_text.innerHTML = "Ver más";
    more_link_anchor.appendChild(more_link_text);

    let more_link_icon = document.createElement("img");
    more_link_icon.src = arrow_right;
    more_link_anchor.appendChild(more_link_icon);

    title_container.appendChild(more_link_anchor);
  }
}

function displaySponsors(main_containe) {
  let container = document.createElement("div");
  createSectionTitle("Patrocinadores", container);

  let sponsors = document.createElement("div");
  container.appendChild(sponsors);

  sponsors.classList.add("social_network_banner");

  let sponsors_available = [
    {
      name: "Punto CHG",
      url: "http://grupovicoru.com/",
      icon: sponsor_puntochg
    },

    {
      name: "Caliente",
      url: "https://www.caliente.mx/ofertas/sponsors/LNBP/",
      icon: sponsor_caliente
    },
    {
      name: "Value",
      url: "https://www.value.com.mx/",
      icon: sponsor_value
    },
    {
      name: "Caja Popular",
      url: "https://www.cpm.coop/",
      icon: caja_popular
    },
    {
      name: "Voit",
      url: "https://www.voit.com.mx/",
      icon: voit
    }
  ];

  sponsors_available.forEach(function (sponsor) {
    let anchor = document.createElement("a");
    anchor.href = sponsor.url;

    let icon = document.createElement("img");
    icon.src = sponsor.icon;

    anchor.appendChild(icon);
    sponsors.appendChild(anchor);
  });

  main_containe.appendChild(container);
}

const methods = {
  displaySponsors: (main_containe) => {
    displaySponsors(main_containe);
  }
};

export default methods;
